<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer"/>
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
          <!-- <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
          </div>-->
           <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>


      <div class="mt-3" v-if="windowWidth >= 992" style="padding:0% 2%">

   <div class="display-div mb-2 point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; max-width:300px;"   v-if="windowWidth <= 1270 && windowWidth >= 770" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"  @click="copyRefCode($event)">
                                        <div >

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>
                                         <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20"  title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>


                    <div class="display-div" style="justify-content:space-between; align-items:center" v-if="windowWidth > 769">

                        <div  style=" flex-basis:30%" :class="{'flex-22': windowWidth < 1614}" >
                            <button style="border-radius:5px; min-height:40px; background:#fff; border:1px solid #fff" @click="sendToBookAmount">
 <img src="../../../assets/images/bookcol3.png" class="mr-2" height="20" >
                Book new amount
            </button>


                                <!-- <i class="fa fa-chevron-down" ></i> -->
                            </div>

                                <div style="flex-basis:40%; display:flex; justify-content:center" v-if="windowWidth > 1270" :class="{'flex-36': windowWidth < 1614}">

                                    <div  style="max-width:300px">
                                    <div class="display-div point-cursor" style="border: 0.5px solid #52DD8F; border-radius:5px;background:#E5F4FF; min-height:38px;align-items:center; padding: 0px 10px" :content="tran.ref_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}"   @click="copyRefCode($event)">
                                        <div>

                                            <img src="../../../assets/images/speaker.png"  height="20">

                                         </div>

                                           <div style="opacity:0; width:1px"> 
                                         <input class="ref-url" type="hidden" :value="`https://requid.me/${userProfileInfo.ref_code}`" >
                                         </div>
                                            <div>
   <p class="point-cursor">{{`https://requid.me/${userProfileInfo.ref_code}`}}</p>
                                             

              </div>
                               
                                                <div class="text-right">

                                                    <img src="../../../assets/images/info.png"  height="20" title="Invite friends using this Referral link and get 1% for every first investment they make." v-tippy='{ arrow : true, placement:"top",arrowType : "round"}'>

              </div>
                                                </div>
                                                </div>
                                            </div>
                                               <div style="flex-basis:30%; display:flex; justify-content:flex-end" :class="{'flex-38': windowWidth < 1614}">
                                            <div>
                                                <div style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center;  padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/bookIcon3.png"  height="20">

              </div>
                                                          <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current booking total</p>

                      <h5 class="text-right">
                      <span>&#8358;</span> {{formatAmount(totalBookedValue)}}
                    </h5>
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>

                                                        </div>
                                                    </div>
                                                      <div >
                                                <div class="ml-2" style="display:flex;border: 0.5px solid #fff; border-radius:5px;background:#fff; min-height:38px;align-items:center; padding: 5px 10px">
                                                    <div>

                                                     <img src="../../../assets/images/info.png"  height="20">

              </div>
                                                        <div>
                      <p
                        style="color: #828282; font-size:12px"
                        class="text-right"
                      >Current accrued interest</p>

            <h5 class="text-right">
                      <span>&#8358;</span> {{formatAmount(totalBookedAccruedInterestValue)}}
                    </h5>
                      <!-- <p class="text-right">retrieving value...</p> -->
                    </div>
                                                        <!-- <div class="text-right">

                                                            <img src="../../assets/images/info.png"  height="20">

              </div> -->
                                                        </div>
                                                        <div>
</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                </div>



        <div>

          <div class="book-home-cover">
            <div class="row">
        

              <div class="col-md-12 col-sm-12 col-12 col-lg-12 col-xl-12 mt-4">
 <!-- <div class="booking-card animated fadeIn" v-if="selectedJobs.length > 0">

                  <div class="display-div mb-3 pb-3" style="border-bottom:0.5px solid #E0E0E0; min-height:30px; align-items:center">
                    <div style=" min-height:20px; flex-grow:0">
                        <img src="../../../assets/images/x2.png" class="mr-3" height="10">
                    </div>
                    <div style=" min-height:20px;">
                      <div style=" min-height:20px; ">
                        <p style="color:#4F4F4F;">({{ selectedJobs.length }}) Selected</p>
                      </div>

                    </div>

                    <div class="text-right " style="position:relative;display:flex; justify-content:flex-end">
                     
                               <img src="../../../assets/images/list2.png" class="mr-4"  height="20">
                
                     
                            <b class="point-cursor" data-toggle="modal"
                      data-target="#exampleModalCenter">Unbook</b>
                      
                     
                    </div>
                  </div>
 </div> -->


<div class="text-center" style="min-height:30vh; display:flex; flex-flow:column; align-items:center; justify-content:center" v-if="bookingHistory.length  < 1">
  <p>Sorry you have not booked any funds yet</p>
       <button style="border-radius:5px; max-width:250px; min-height:40px; background:#fff; border:1px solid #fff" @click="sendToBookAmount" class="mt-4">
 <img src="../../../assets/images/bookcol3.png" class="mr-2" height="20" >
                Book Funds
            </button>
</div>



                  <div class="booking-card mb-2" style="border-bottom:0.5px solid #E0E0E0;"   v-if="windowWidth < 992">
                
                    <div style=" min-height:30px; display:flex; align-items:center min-width:100%">
                      <div style=" min-height:20px; flex:1 ">
                        <p style="color:#828282; font-size:14px">Current booking total</p>
                        <p style="color:#828282; font-size:18px" > <span>&#8358;</span> {{formatAmount(totalBookedValue)}}</p>
                      </div>
                       <div style="justify-content:space-between">
                         <p style="color:#828282; font-size:14px; opacity:0">Current Booking Total</p>
                        <p class="text-right" style="color:#828282; font-size:14px" >At {{bookingRate}}% per annum</p>
                      </div>
                  
                    </div>
                    <div class="mt-1 mb-1" style="border-top: 0.5px solid #E0E0E0;"></div>
                    <div  style=" min-height:40px; display:flex; align-items:center; min-width:100%">
                         <div class="mt-1" style="flex:1">
                        <p style="color:#828282; font-size:14px">Current accrued interest</p>
                        <p style="color:#2FA8A8; font-size:18px">    <span>&#8358;</span> {{formatAmount(totalBookedAccruedInterestValue)}}</p>
                      </div>
                      <div class="mt-1 text-right" style="border-left:1px solid #E0E0E0; flex:1">
 <img src="../../../assets/images/bookplus.png" class="mr-2 point-cursor" height="30" @click="sendToBookAmount">
  <img src="../../../assets/images/rightarrow.png" class="mr-2" height="10">
                      </div>
                    </div>
                  </div>













  <div class="booking-card  mb-3 mt-3" style="background:#F0F0F0" v-if="bookingHistory.length > 0">
                  <div style="position:relative">
                    <img
                      src="../../../assets/images/grey-eclipse.png"
                      height="50"
                      style="position: absolute; right: -11px; top: -10px;"
                    >
                  </div>
                  <div class="display-div">
                    <div style="display:flex; align-items:center">
                      <img src="../../../assets/images/infocol.png" class="mr-2" height="30">
                       <p>Interest on funds <span><b>less than {{bookingDuration}} days</b></span> of booking cannot be accessed by user.</p>
                    </div>
                  </div>
                 

               
                </div>
            
                <div class="booking-card"  v-if="bookingHistory.length > 0">

                  <div class="pb-3" style="display:flex; max-width:400px; min-height:50px; align-items:center;border-bottom: 1px solid #E0E0E0;">
      <p style="color:#828282; font-size:18px" class="mr-2"  v-if="bookingHistory.length > 0">Filter</p>

<div style="min-height:50px;
border: 0.5px solid #BDBDBD;border-radius: 26px;    min-width: 69%;display:flex;padding:5px">

<div class="point-cursor" style="min-height:20px;
 display:flex;align-items:center;justify-content:center; flex-basis:50%" @click="saveTransType('active')" :class="{'active-book-filter':activeTransType == 'active'}">

<p>Active books</p>
</div>
<div class="point-cursor" @click="saveTransType('both')" :class="{'active-book-filter':activeTransType == 'both'}" style="min-height:20px;
 flex-basis:50%; display:flex;align-items:center;justify-content:center">
<p>Active & inactive</p>
</div>

</div>
                  </div>

<template>
  <div enter-active-class="animated fadeInUp" leave-active-class="animated fadeOutUp" :duration="{ enter: 1000, leave: 1000 }" is="transition-group">
    

                  <div class="mb-2" style="border-bottom:0.5px solid #E0E0E0;" v-for="book in filterBookData(bookingHistory)" :key="book.id" :class="{'fade-elem': book.status == 2}">
                    <!-- <div style=" min-height:20px; flex-grow:0">
                       <div class="custom-control custom-checkbox point-cursor">
                      <input type="checkbox"  class="custom-control-input point-cursor" id="customControlInline2">
                      <label class="custom-control-label point-cursor" for="customControlInline2">
                      
                      </label>
                    </div>
                    </div> -->
                    <div style=" min-height:50px; display:flex; align-items:center; min-width:100%">
                      <div style=" min-height:20px; flex:1; flex-basis:30%; ">
                        <p style="color:#4F4F4F; font-size:18px"><span>&#8358;</span>{{formatAmount(book.booked_amount)}} <span style="font-size:12px; color:#828282">from wallet </span> </p>
                      </div>


<div style=" flex:1; flex-basis:30%;min-height:50px; display:flex ; align-items:center " v-if="windowWidth > 767">
                           <p style="color:#828282; font-size:12px">{{book.created_at | formatDate}}</p>
                      </div>

                       <div style="display:flex; flex-basis:20%; justify-content:flex-end; min-height:30px; align-items:center" class="text-right">
                        <!-- <div
                          style=" border-radius:15px; border:1px solid #F5F5F5; min-width:90px; background:#F5F5F5; display:flex; justify-content:space-between; align-items:center"
                          class="mr-2 text-right"
                        >
                          <span>  <img src="../../../assets/images/walletdark.png" height="23"></span>
                          <img src="../../../assets/images/arrow2.png" height="20">
                          <img src="../../../assets/images/bookcol.png" height="20">
                        </div> -->

                        <div v-if="windowWidth > 767">

                              <p style="color:#BDBDBD; font-size:12px"  v-if="book.numberOfDaySpent < bookingDuration" > 
                                 <span>&#8358;</span> {{formatAmount(book.interest_accrued)}} unprepared </p>

                        <p style="color:#4F4F4F; font-size:12px" v-else >  <span>&#8358;</span> {{formatAmount(book.interest_accrued)}} accrued </p>
                        </div>
                       
                        <div style="border-left:0.5px solid #E0E0E0;" class="pl-1 ml-2" v-if="book.status
                        !== 2">
                          <button 
                          style="border-radius:15px; padding:0px; border:1px solid #fff; background:#fff; " data-toggle="modal"
                          data-target="#exampleModalCenter" @click="saveUnbookObject(book)">

                          <img src="../../../assets/images/close-minus.png" class="pl-4" height="30">
                        </button>
                        </div>
                    
                      </div>
                  
                    </div>

                    <div  style=" min-height:40px; display:flex; align-items:center min-width:100%"  v-if="windowWidth <= 767">
                         <div class="mt-1" style="flex:1">
                        <p style="color:#828282; font-size:12px">{{book.created_at | formatDate}}</p>
                      </div>
                      <div class="mt-1">
                        <p style="color:#BDBDBD; font-size:12px" v-if="book.numberOfDaySpent < bookingDuration">  <span>&#8358;</span>{{formatAmount(book.interest_accrued)}} unprepared</p>
                        <p style="color:#4F4F4F; font-size:12px" v-else >  <span>&#8358;</span>{{formatAmount(book.interest_accrued)}} accrued</p>
                      </div>
                    </div>
                  </div>
  </div>
</template>
                </div>


                <!-- <table>
    <thead>
      <tr><th>Jobs</th></tr>
    </thead>
    <tbody>
      <tr v-for="job in compJobsList" :key="job.id">
        <td>
          <input type="checkbox"
            :value="job" 
            v-model="selectedJobs">
            <label for="">{{ job.skuDescription }}</label>
        </td>
      </tr>
    </tbody>
  </table>
  <span>Checked jobs: {{ selectedJobs.length }}</span> -->
              </div>
          
            </div>
          </div>
          
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" style="max-width: 450px;" role="document">
            <div class="modal-content" style="background:#fff;">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
     
                
                      <h6  class="text-center pb-3" style="color:#828282">Unbook this item</h6>
                      <p class="text-center" style="color:#828282">You are about to unbook this item from your list</p>

                      <div style="display: flex; justify-content: center;">

                 <div style="border-top:0.5px solid #E0E0E0; width:100px" class="mt-2  mb-2"></div>
                      </div>
                
                <div class="booking-card mt-4" style="padding:0px">


                  <div class="display-div mb-3" style="border-bottom:0.5px solid #E0E0E0;">
                    
                    <div style=" min-height:20px;">
                      <div style=" min-height:20px; ">
                        <p style="color:#4F4F4F; font-size:14px"><span>&#8358;</span>{{formatAmount(unBookObject.booked_amount)}}</p>
                      </div>
                      <div>
                        <p style="color:#828282; font-size:14px">Ready for unbooking</p>
                      </div>
                    </div>

                    <div class="text-right" style="position:relative">
                      <div>
                       <p style="color:#BDBDBD;font-size:14px" v-if="unBookObject.numberOfDaySpent < bookingDuration"> +  <span>&#8358;</span>{{formatAmount(unBookObject.interest_accrued)}} </p>
                       <p style="color:#2FA8A8;font-size:14px" v-else> +  <span>&#8358;</span>{{formatAmount(unBookObject.interest_accrued)}} </p>
                         
                      
                      </div>
                      <div>
                        <p style="color:#828282; font-size:14px" v-if="unBookObject.numberOfDaySpent < bookingDuration">access in {{bookingDuration - unBookObject.numberOfDaySpent}} day(s)</p>
                        <p style="color:#828282; font-size:14px" v-else>ready</p>
                      </div>
                    </div>
                  </div>

                </div>
               <!-- <hr> -->
           
                  <!-- <div style="position:relative">

                      <p style="color:#4F4F4F">Total amount to unbook</p>
                      <p class="text-right" style="color: #2FA8A8"> 12,000</p>
                  </div>
          
                  <hr> -->
                  <div 
                    style="display:flex; min-height:50px;align-items:center; justify-content:center"
                    class="point-cursor" @click="unBookFunds" data-dismiss="modal" aria-label="Close"
                  >
                    <p style="color:#0094FF;" class="mr-3">Complete Unbooking</p>
                  </div>
            
              </div>
            </div>
          </div>
        </div>
        </div>

    
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";
import http from "../../../http/index";
import api from "../../../apis/index";
import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {},
          replenJobsList: [
      { rplFrom: 'example@example.com', replenTo: 'foo@bar.com', sku: 1, description: '11111', unitsToReplenish: 33 },
      { rplFrom: 'example@example.com', replenTo: 'foo@bar.com', sku: 2, description: '22222', unitsToReplenish: 22 },
      { rplFrom: 'example@example.com', replenTo: 'foo@bar.com', sku: 3, description: '33333', unitsToReplenish: 11 },
    ],
      selectedJobs: [],
      activeTransType:'both',
      unBookObject:{
id:'',
        numberOfDaySpent:0,
        interest_accrued:'',
        booked_amount:0
      }
    };
  },
  methods: {

    saveTransType(transType){
this.activeTransType = transType

    },

    sendToBookAmount() {
      this.$router.push("/book/amount");
    },
   saveUnbookObject(data){

     this.unBookObject = data
   },
           unBookFunds() {


            let vm = this
            vm.$store.commit("market/startPreloader");
           
            //   this.$helpers.log(amountData, 'getAmount')
            http.delete(api.bookFunds,{
          data: { booking_id: this.unBookObject.id }
        })

                .then(response => {
                    vm.$store.commit("market/stopPreloader");

        this.$router.push({
        path: "/unbook/success",
        query: { fundAmount: this.unBookObject.booked_amount }
      });
                      vm.$helpers.log(response);
                })

                .catch(error => {
                    if (error.response) {

                        if (error.response.data.message !== "Unauthenticated.") {
                            vm.$store.commit("domElements/openNotificationMessage")

                            vm.$store.commit("domElements/saveNotificationMessage", error.response.data.message)
                                 vm.$helpers.log(error.response);
                                     vm.$store.commit("market/stopPreloader");
                        }
                    }
                    this.$store.commit("market/stopLoader");
                });

        },
                filterBookData(tableData) {



            if(this.activeTransType !== 'both'){

            return tableData.filter(data => {

               
               
                    return data.status == 1 ;
    
  
            });
                
            }
            else{

                return tableData
            }

        },

  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
      
       bookingRate: state => state.market.bookingSettings.default_rate,
      bookingMinimumAmount: state => state.market.bookingSettings.minimum_amount,
      bookingDuration: state => state.market.bookingSettings.minimum_duration_days,
      bookingHistory: state => state.market.bookingHistory,
        bookingDuration: state => state.market.bookingSettings.minimum_duration_days,
    }),
compJobsList() {
      return this.replenJobsList.map((job, index) => ({
          id: index,
          manualMoveBatchId: 0,
          modifyDate: new Date().getTime(),
          moveFrom: job.replFrom,
          moveTo: job.replenTo,
          sku: job.sku,
          skuDescription: job.description,
          status: 'active',
          units: job.unitsToReplenish,
        }));
    },
  
  },
  mounted() {
   let heading = {
      main: this.tran.booking
    };
 
  
    this.$store.commit("market/savePageHeading", heading);
    this.$store.dispatch("market/getBookingHistory");
    this.$store.dispatch("market/getBookFundsSummary");
  this.$store.dispatch("market/getBookingSettings");
     this.$store.commit(
      "auth/saveBookRouteVisited",
      this.$route.path
    );
  },


};
</script>

<style scoped>
.booking-card {
  background: #fff;
  min-height: 50px;
  border-radius: 10px;
  padding: 10px;

  overflow: hidden !important;
}
.book-home-cover {

  display: flex;
  align-items: center;
    padding-bottom: 5%;

}



</style>
