<template>
<div>
    <bookingHistory />
</div>
</template>

<script>
import bookingHistory from '../../components/dashboard/booking/BookingHistory'
export default {
    components: {
        bookingHistory
    }
}
</script>

<style>
